<script setup></script>

<template>
  <nav class="navbar navbar-expand-lg pb-4">
    <div class="d-flex justify-content-between container-fluid">
      <a class="nav-brand d-block d-lg-none" href="#">
        <img class="img-fluid p-1" width="70" src="../assets/PNG LOGO 2 w.png" alt="" />
      </a>
      <button
        class="navbar-toggler text-center"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list text-light" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
      </svg>
      </button>
      <div class="collapse navbar-collapse text-uppercase mt-5" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#partners">partners</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#whoWeAre">about us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#factory">factory</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#showroom"> showroom</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase" href="#contacts">
            <button class="d-block d-lg-none p-2 btn btn-outline-light contactButton w-100 text-start rounded rounded-0">
              CONTACT US
            </button>
            </a>
          </li>
        </ul>
      </div>

      <a
        class="position-absolute mt-4 start-50 translate-middle-x d-none d-lg-block"
        href="#"
      >
        <img class="img-fluid" width="150" src="../assets/PNG LOGO 2 w.png" alt="" />
      </a>
      <a class="nav-link text-uppercase d-none d-lg-block" href="#contacts">
      <button
        type="button"
        class="nav-item p-2 mt-4 btn btn-outline-light contactButton"
      >
        CONTACT US
      </button>
      </a>
    </div>
  </nav>
</template>

<style scoped>
.show {
  background-color: #cc2d2d;
}
.nav-link.active,
.nav-link:active,
.nav-link:focus {
  color: #cc2d2d !important;
}
li a {
  color: #fafafa;
  font-size: larger;
  padding-left: 1rem;
}
li a:hover {
  color: #cc2d2d;
}
.contactButton {
  color: #fafafa;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 119px;
  margin-right: 1rem;
}
.navbar {
  position: relative;
}

.navbar::before {
  content: "";
  position: absolute;
  bottom: 0; /* Position at the top */
  left: 50%; /* Center the line horizontally */
  width: 95%; /* Set the length of the "border" */
  height: 1px; /* Set the thickness */
  background-color: black; /* Border color */
  transform: translateX(-50%); /* Center the line */
}
@media (max-width: 575.98px){
  .nav-link.active,
.nav-link:active,
.nav-link:focus {
  color: #cc2d2d !important;
  background-color: #fafafa !important;
}

  .navbar::before {
  content: "";
  position: absolute;
  bottom: 0; /* Position at the top */
  left: 50%; /* Center the line horizontally */
  width: 95%; /* Set the length of the "border" */
  height: 0; /* Set the thickness */
  background-color: black; /* Border color */
  transform: translateX(-50%); /* Center the line */
}
}
</style>
