<script setup>
import { ref, reactive, onMounted, onBeforeUnmount,  } from 'vue'
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Swiper event handlers
const onSwiper = (swiper) => {
  console.log(swiper);
};

const onSlideChange = () => {
  console.log("slide change");
};

// Reactive state to manage whether navigation is enabled
const swiperConfig = reactive({
  showNavigation: window.innerWidth >= 768, // Show navigation only if screen is >= 768px
  modules: window.innerWidth >= 768 ? [Navigation, Pagination, Scrollbar, A11y] : [Pagination, Scrollbar, A11y],
});

// Update the swiperConfig based on window size
const updateSwiperConfig = () => {
  swiperConfig.showNavigation = window.innerWidth >= 768;
  swiperConfig.modules = window.innerWidth >= 768 ? [Navigation, Pagination, Scrollbar, A11y] : [Pagination, Scrollbar, A11y];
};

// Add event listener for screen resize
onMounted(() => {
  window.addEventListener("resize", updateSwiperConfig);
});

// Cleanup the event listener
onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSwiperConfig);
});
// Swiper modules
const modules = [Navigation, Pagination, Scrollbar, A11y];
</script>

<template>
<div class="showroomSection">
  <div class="mx-2 mx-md-5 p-5 position-relative">
    <div class="row row-cols-2">
      <div class="col-12 col-md-6 p-2">
          <h2 class="display-2 text-center text-md-start  text-uppercase fw-bold text-white">
            our <br class="d-none d-md-block">
            SHOWROOM.
          </h2>
          <a href="https://g.co/kgs/tKXFRfp" target="_blank">
          <img class="img-fluid mapImage mt-4" src="../assets/sections/showroom/map.png" alt="">
          </a>
          <h3 class="mt-5 text-white text-center text-md-start fs-3 text-uppercase lh-base">
            <span class="fw-bolder">our location: -</span>
            <span class="address">ELMOKKATAM 
              AL-QODS ST. FROM 9TH STREET
            </span>
          </h3>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <!-- Swiper -->
          <Swiper
          :modules="modules"
          :navigation="swiperConfig.showNavigation"
          pagination
          :slides-per-view="1"
          :space-between="50"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          >
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/1.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/2.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/3.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/4.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/5.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/6.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/7.jpg" alt="">
            </SwiperSlide>
            <SwiperSlide class="mb-5">
              <img class="img-fluid rounded-5" src="../assets/sections/showroom/8.jpg" alt="">
            </SwiperSlide>
          </Swiper>
          <!-- Swiper -->
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.showroomSection{
  position: relative;
  background: linear-gradient(-65deg, #880000 0%, #B50000 0%, #240101 60%);
}
.address{
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
a{
  color: white;
  text-decoration: none;
}
@media (min-width: 575.98px) {
.mapImage{
  margin-top: 2.5vw ;
  width: 25vw;
}
}
</style>