
<script setup>

</script>
<template>
  <div class="pt-3 pt-md-5 clientsSection">
    <h1 class="text-center clientsHeading">CLIENTS TRUSTED US.</h1>
    <div class="d-flex justify-content-center align-items-center mt-2 mt-md-4">
      <img class="img-fluid " src="../assets/sections/clients/Emblem_of_the_Egyptian_Armed_Forces.png" alt="">
      <img class="img-fluid mx-3 mx-md-5" src="../assets/sections/clients/al-ahram-logo-6E1489F7B0-seeklogo.com.png" alt="">
      <img class="img-fluid " src="../assets/sections/clients/images.png" alt="">
    </div>
  </div>
</template>

<style scoped>
.clientsSection{
  background: linear-gradient(0deg, #fafafa 0%, #B50000 100%);
}
.clientsHeading{
  color:#ffffff;
  font-weight: 700;
  font-size: 3.5vw;
}
img{
  width: 10vw;
}
@media (max-width: 768px) {
  img{
    margin-top: 2vw;
    width: 15vw;
  }
}
</style>