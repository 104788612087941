<script setup></script>

<template>
  <div class="mx-2 mx-md-5 position-relative text-uppercase">
    <div class="d-flex flex-column align-items-end">
      <h1 class="text-center w-50 mt-4 mt-md-5 sectionHeading">why us?</h1>
    </div>
    <div class="d-flex flex-column align-items-end pe-md-3 aboutContainer">
      <div class=" w-50 py-1 py-md-4 row row-cols-2 me-0 me-md-4">
        <div class="col-6 col-md-6">
          <p class="paragraphAbout">
            by the name of Zogmar,<span> 13 <br> certified </span>distributor that works with us in Cairo &
            Giza.
          </p>
        </div>
          <div class="col-6 col-md-6">
          <p class="paragraphAbout">
            we have an assembly & preparation <span> factory </span> that workin by the latest
            technology.
          </p>
        </div>
        <div class="col-6 col-md-6">
          <p class="paragraphAbout">
            Exclusive agent for the <span>biggest factories & <br> brands</span> that works in spare parts.
          </p>
        </div>
        <div class="col-6 col-md-6">
          <p class="paragraphAbout">we have distributors in all of<span> egypt. </span></p>
        </div>
        <div class="col-6 col-md-6">
          <p class="paragraphAbout">
            <span>Post sale services:</span>
             warranties against defects, maintanance, spare parts
            availability.
          </p>
        </div>
        <div class="col-6 col-md-6">
          
        </div>
      </div>
    
    <img
      class="img-fluid imageAbout"
      src="../assets/sections/whoWeAre/rider new logo 2.png"
      alt=""
    />
    </div>
  </div>
</template>

<style scoped>
.sectionHeading {
  font-size: 5.5vw;
  font-weight: 600;
}
span {
  color: #cc2d2d;

}
.paragraphAbout {
  font-weight: 600;
  font-size: 1.5vw;
}
.imageAbout {
  position: absolute;
  bottom: -1vw;
  left: 3vw;
  width: 34vw;
  height: auto;
}
.aboutContainer {
  position: relative;
  border-right-style: solid;
  border-width: 1px;
  border-bottom-style: solid;
  border-color: #cc2d2d;
  border-radius: 2vw;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25); /* Add shadow */
}
@media (max-width: 575.98px) {
  .imageAbout {
    position: absolute;
    bottom: 3vw;
    left: 1vw;
    width: 45vw;
  }
  .paragraphAbout {
  font-weight: 600;
  font-size: 2vw;
}
  .col-6 {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}
}
</style>