<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="row shadow-lg p-4 rounded">
      <!-- Left Section -->
      <div class="col-lg-6 contacts p-4 d-none d-md-flex flex-column justify-content-center align-items-center text-white text-center">
        <img src="../assets/PNG LOGO 2 w.png" alt="Zogmar Egy Motors" width="300" class="img-fluid mb-3" />
        <h2 class="display-5 fw-bold">GLAD TO DEAL WITH YOU</h2>
      </div>

      <!-- Right Section - Form -->
      <div class="col-lg-6 p-4 bg-light">
        <h3 class="text-center text-danger mb-4">CONTACT US</h3>
        <form @submit.prevent="sendForm">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" class="form-control" id="name" v-model="form.name" required>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Phone</label>
            <input type="tel" class="form-control" id="phone" v-model="form.phone" required>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" v-model="form.email" required>
          </div>
          <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea class="form-control" id="message" rows="4" v-model="form.message" required></textarea>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-danger w-50">SEND</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Bootstrap Modal for Submission Message -->
    <div class="modal fade" id="submissionModal" tabindex="-1" aria-labelledby="submissionModalLabel" aria-hidden="true" ref="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-bg-success">
            <h5 class="modal-title" id="submissionModalLabel">Thank you for contact us.</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ modalMessage }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import emailjs from 'emailjs-com';
import { Modal } from 'bootstrap';

export default {
  setup() {
    const form = ref({
      name: '',
      phone: '',
      email: '',
      message: ''
    });

    const modal = ref(null); // Reference to the modal element
    const modalInstance = ref(null); // Holds the Bootstrap modal instance
    const modalMessage = ref(''); // Message to show in the modal

    // Open Modal with message
    const openModal = (message) => {
      modalMessage.value = message;
      modalInstance.value.show(); // Show the modal
    };

    // Close Modal
    const closeModal = () => {
      modalInstance.value.hide(); // Hide the modal
    };


    const sendForm = () => {
      const serviceID = 'service_9cv21zk';
      const templateID = 'template_twl34oi';
      const userID = '-npj6EveLiFVcuseU';
      
      emailjs.send(serviceID, templateID, {
        name: form.value.name,
        phone: form.value.phone,
        email: form.value.email,
        message: form.value.message
      }, userID)
      .then(() => {
        openModal('We successfully received your contact request and we will reach you soon!');
      })
      .catch(() => {
        openModal('Failed to send email. Please try again.');
      });
    };
    // Initialize Bootstrap modal after component is mounted
    onMounted(() => {
      modalInstance.value = new Modal(modal.value, {
        backdrop: true,
        keyboard: true
      });
    });

    // Cleanup modal instance when the component is unmounted
    onUnmounted(() => {
      if (modalInstance.value) {
        modalInstance.value.dispose();
      }
    });

    return { form, modal, modalMessage, sendForm, closeModal };
  }
};
</script>

<style scoped>
.contacts{
  background-image: url('../assets/sections/contacts.png');
  background-size: cover;
}
.vh-100 {
  min-height: 100vh;
}
.bg-danger {
  background-color: #FF0000 !important;
}
</style>
