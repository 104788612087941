<script setup>

</script>

<template>
<div class="zogmarName zogmarMainText mainTextSize text-white z-2">
  <h1>ZOGMAR <span class="d-block">EST.1950</span>  </h1>
</div>
<div class="zogmarSlogan zogmarMainText text-white z-2">
  <h1>ENJOY THE <span class="d-block">RIDE.</span>  </h1>
</div>
  <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="w-100 h-100" src="../assets/bg/Rectangle 13.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="w-100 h-100" src="../assets/bg/Rectangle 14.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="w-100 h-100" src="../assets/bg/Rectangle 16.png" alt="">
    </div>
    <div class="carousel-item">
      <img class="w-100 h-100" src="../assets/bg/Rectangle 17.png" alt="">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon d-none" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon d-none" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</template>

<style scoped>
@font-face {
  font-family: 'CustomFont';
  src: url('@/assets/Azeri-Sans-Regular/AzeriSans-Bold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}
.zogmarMainText{
    font-family: 'CustomFont', sans-serif;
  letter-spacing: 3px ;
  position: absolute;
}
.zogmarName{
  top:19%;
  left:3%;
}
.zogmarSlogan{
  right: 1%;
  top: 70%;
}
.zogmarSlogan h1{
  font-size: 4vw;
}
.mainTextSize  h1{
  color:#cc2d2d;
  font-size: 5.5vw;
}
.zogmarName span{
  font-size: 3.5vw;
}
@media (max-width: 575.98px){

.zogmarName{
  top:30%;
  left:3%;
}
.zogmarSlogan{
  right: 3%;
  top: 78%;
}
}

</style>