<script setup>

</script>

<template>
  <div class="mx-2 mx-md-5 p-5 position-relative">
  <div class="d-flex justify-content-between upperImages z-3">
    <img class="img-fluid" src="../assets/sections/factory/Rectangle 1.png" alt="">
    <img class="img-fluid" src="../assets/sections/factory/Rectangle 3.png" alt="">
    <img class="img-fluid" src="../assets/sections/factory/Rectangle 4.png" alt="">
  </div>
<div class=" text-uppercase sectionFactory">
  <div class="row row-cols-2"> 
    <div class="col-12 col-md-6 mb-2 d-block d-lg-none">
    <h2 class="w-100 text-center">
      our factory
    </h2>
    </div>
  <div class="col-12 col-md-6">
    <p>
      Our factory established in 2009 positioned at Abo Rawash to fully assemble & prepare motorcycle.
    </p>
    <h3 class=" display-6">spare parts we work on:</h3>
    <ul>
      <li>
        headlight
      </li>
      <li>
        turnsignal
      </li>
      <li>
        brake caliper
      </li>
      <li>
        hand clutch
      </li>
      <li>
        ignition switch speedometer housing
      </li>
      <li>
        full motorcycle chassis
      </li>
      <li>
        motorcycle horn
      </li>
    </ul>
    <p>our spare parts warehouses space: 1400M, 1000M</p>
  </div>
  <div class=" d-none d-lg-block col-12 col-md-6">
    <dir class="d-flex justify-content-center align-items-center w-100 h-75">
    <h2>
      our <br> factory.
    </h2>
    </dir>
    </div>
  </div>
  <img class="img-fluid lowerImage" src="../assets/sections/factory/Rectangle 5.png" alt="">
</div>
</div>
</template>

<style scoped>
.sectionFactory{
  margin-top: 3vw;
  padding: 5vw;
  padding-top: 10vw;
  position: relative;
  border-radius: 4vw;
  background: linear-gradient(-65deg, #880000 0%, #B50000 0%, #240101 60%);

}
li, ul, h2, h3, p{
  color: #ffffff;
}

h2{
  font-size: 5vw;
}
.upperImages{
  position: absolute;
  left: 8vw;
  height: 10vw;
  width: 35vw;
}
.lowerImage{
  position: absolute;
  right: 3vw;
  bottom: -4vw;
  width: 40vw;
}
@media (max-width: 575.98px) {
  h2{
    margin-top: 2vh;
    font-size: 8vw;
  }
  h3{
    font-size: 6vw;
  }
  .upperImages{
    left: 15vw;
    top:5vw;
    height: 8vh;
    width: 65vw;
  }
  .lowerImage{
    width: 50vw;
  bottom: -7vw;

  }
}
</style>