<script setup></script>

<template>
  <div class="mx-2 mx-md-5 position-relative text-uppercase">
    <h1 class="text-start mt-4 mt-md-5 sectionHeading">Who are we?</h1>
    <div class="ps-1 ps-md-3 pt-3 aboutContainer">
      <div class="w-50">
      <h2 class="headingAbout">quality masters</h2>
      <p class="paragraphAbout">we import original <br> motorcycle spare <br> parts brands.</p>
    </div>
    <div class="w-50">
      <h2 class="headingAbout">pioneers</h2>
      <p class="paragraphAbout">zogmar brand is the <br> first to import <br> motorcycles & its spare <br> parts to egypt.</p>
    </div>
    <div class="w-50">
      <h2 class="headingAbout">highly trusted</h2>
      <p class="paragraphAbout">we distribute to all <br> egypt's governorates.</p>
    </div>
    </div>
    <img
      class="img-fluid imageAbout"
      src="../assets/sections/whoWeAre/rider new logo 1.png"
      alt=""
    />
  </div>
</template>

<style scoped>
.sectionHeading{
  font-size: 5.5vw;
  font-weight: 600;
}
.headingAbout {
  color: #cc2d2d;
  font-size: 2.50vw;
  font-weight:600;
}
.paragraphAbout{
  font-weight:600;
  font-size: 1.80vw;
}
.imageAbout{
  position:absolute;
  bottom: -1.5vw;
  right: 3vw;
  width: 39vw;
  height: auto;
}
.aboutContainer{
  border-left-style: solid;
  border-width: 1px;
  border-bottom-style: solid;
  border-color: #cc2d2d;
  border-radius: 2vw;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25); /* Add shadow */
}
@media (max-width: 575.98px) {
  .imageAbout{
    position:absolute;
    top: 15vw;
    right: 0.75vw;
    width: 50vw;
  }
  .headingAbout{
    font-size: 4vw;
  }
  .paragraphAbout{
    font-size: 3vw;
    }
}
</style>
